@import 'Master';

.Essay-Quote {
    @extend .Flex;
    @extend .Gap-M;
    padding: $space-S $space-M;

    border-left: 1px solid $black-3;

    > p {
        padding: 0;
        margin: 0;
    }
}