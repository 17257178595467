@import 'Margin';
@import 'Display';

.D-None {
    display: none;
}

.H-100-VH {
    height: 100vh;
}

.Flex {
    display: flex;
}

.F-Col {
    flex-direction: column;
}

.F-Align-Center {
    align-items: center;
}

.F-Justify-Center {
    justify-content: center;
}

.F-Align-Self-Start {
    align-self: flex-start;
}

.F-Grow {
    flex-grow: 1;
}

.Gap-0 {
    gap: 0;
}

.Gap-S {
    gap: $space-S;
}

.Gap-M {
    gap: $space-M;
}

.Gap-L {
    gap: $space-L;
}

.Gap-XL {
    gap: $space-XL;
}

.Gap-XXL {
    gap: $space-XXL;
}