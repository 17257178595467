.Margin-0 {
    margin: 0;
}

.Margin-S {
    margin: $space-S;
}

.Margin-M {
    margin: $space-M;
}

.Margin-L {
    margin: $space-L;
}

.Margin-XL {
    margin: $space-XL;
}

.Margin-XXL {
    margin: $space-XXL;
}

.Margin-Top-S {
    margin-top: $space-S;
}

.Margin-Top-M {
    margin-top: $space-M;
}

.Margin-Top-L {
    margin-top: $space-L;
}

.Margin-Top-XL {
    margin-top: $space-XL;
}

.Margin-Top-XXL {
    margin-top: $space-XXL;
}

.Margin-Right-S {
    margin-right: $space-S;
}

.Margin-Right-M {
    margin-right: $space-M;
}

.Margin-Right-L {
    margin-right: $space-L;
}   

.Margin-Right-XL {
    margin-right: $space-XL;
}

.Margin-Right-XXL {
    margin-right: $space-XXL;
}

.Margin-Bottom-S {
    margin-bottom: $space-S;
}

.Margin-Bottom-M {
    margin-bottom: $space-M;
}

.Margin-Bottom-L {
    margin-bottom: $space-L;
}

.Margin-Bottom-XL {
    margin-bottom: $space-XL;
}

.Margin-Bottom-XXL {
    margin-bottom: $space-XXL;
}

.Margin-Left-S {
    margin-left: $space-S;
}

.Margin-Left-M {
    margin-left: $space-M;
}

.Margin-Left-L {
    margin-left: $space-L;
}

.Margin-Left-XL {
    margin-left: $space-XL;
}

.Margin-Left-XXL {
    margin-left: $space-XXL;
}