@import "../Master";

.Result-Section {
    display: flex;
    width: 100%;
    padding-top: $space-M;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-M;

    .Block-Heading {
        display: flex;
        padding: 0;
        align-items: center;
        gap: $space-S;
        align-self: stretch;
    }

    .Chips {
        display: flex;
        padding: 0;
        align-items: flex-start;
        align-content: flex-start;
        gap: 12px $space-S;
        align-self: stretch;
        flex-wrap: wrap;

        p {
            @extend .Black-1;
            @extend .Body-2;

            padding: $space-S $space-M;
            text-align: left;

            border-radius: $space-L;
            border: 1px solid $black-3;
            background: $white-1;
        }
    }
}