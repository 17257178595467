@import "../Master";

.DDBody {
    position: relative;
    padding: 0 0 0 0;

    z-index: 1;

    .Container {
        position: relative;
        z-index: 2;
    }

    .Container-Theme-2 {
        display: flex;
        padding: 0;
        flex-direction: column;
        align-items: center;
        gap: $space-XL;
        align-self: stretch;
    }

    .Component-Theme-1 {
        display: flex;
        padding: 0 0 $space-XXL 0;
        flex-direction: column;
        align-items: center;
        gap: $space-XXL;
    }

    .Component-Theme-2 {
        display: flex;
        padding: 0;
        flex-direction: column;
        align-items: flex-start;
        gap: $space-L;
    }

    .Component {
        padding: 0 40px;

        @media (min-width: $desktop-resolution) {
            width: 640px;
            margin: 0 auto;
            padding: 0;
        }

        .Body {
            display: flex;
            padding: 0;
            flex-direction: column;
            align-items: flex-start;
            gap: $space-M;
        
            .Heading {
                @extend .Black-1;
                @extend .Title-2;
            }
        
            p {
                @extend .Black-1;
                @extend .Body-2;
            }
        
            .Text-1 {
                @extend .Green-1;
                @extend .Body-1;
            }
        
            .Quote {
                display: flex;
                padding: $space-S $space-M;
                flex-direction: column;
                align-items: flex-end;
                gap: 10px;
                align-self: stretch;
        
                border-radius: $space-S;
                background: $white-3;
        
                p {
                    @extend .Black-1;
                    @extend .Body-2;
                }
        
                .By {
                    display: flex;
                    padding: 0;
                    align-items: center;
                    gap: $space-S;
                }
            }
        
            ol {
                margin: 0;
                padding: 0 0 0 $space-M;
        
                li {
                    @extend .Black-1;
                    @extend .Body-2;
                }
            }
        
            .Heading-Section {
                display: flex;
                padding: 0;
                flex-direction: column;
                align-items: flex-start;
                gap: 0;
        
                .Heading {
                    @extend .Black-2;
                    @extend .Title-3;
                }
        
                .Subheading {
                    @extend .Black-1;
                    @extend .Title-2;
                }
            }
        }
    }
}