@import "../Master";

.Solution-Section-Border {
    box-sizing: border-box;
    width: 100%;
    /* Gradient Border */
    background: 
    linear-gradient(#fff 0 0) padding-box, /*this is your grey background*/
    linear-gradient(135deg, $diagonal-card 0%, $white-1 50%) border-box;
    border: 1px solid transparent;
    /* Shadow_solution */

    box-shadow: 8px 2px 18px 0px rgba(62, 132, 120, 0.09), 33px 15px 33px 0px rgba(62, 132, 120, 0.07), 74px 30px 45px 0px rgba(62, 132, 120, 0.04), 132px 50px 55px 0px rgba(62, 132, 120, 0.02);

    @extend .Corner-Radius;

    // Default padding
    padding: $space-L $space-M;
}