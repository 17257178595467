@import "Master";

.Login-Page {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100vh;
}

.Login-Box {
    display: flex;
    flex-direction: column;
    gap: $space-M;

    > .Logo {
        align-self: center;
        width: 100px;
        height: 10px;
    }
}

.Login-Form {
    display: flex;
    flex-direction: column;

    width: 100%;

    input[type='text'], input[type='password'] {
        @extend .Body-2;
        padding: $space-S $space-M;
    }

    .Button-Login {
        @extend .Body-2;
        @extend .White-1;
        background-color: $black-2;
        border: none;
        cursor: pointer;
        padding: $space-S $space-M;
        
    }
    .Button-Login:hover {
        background-color: $black-1;
    }
}