@import "../Master";

.Timeline {
    display: flex;
    padding: 0;
    align-items: flex-start;
    gap: $space-S;
    align-self: stretch;

    flex-direction: column;

    @media (min-width: $desktop-resolution) {
        flex-direction: row;
    }

    .Left {
        display: none;

        @media (min-width: $desktop-resolution) {
            display: flex;
            padding: 0;
            flex-direction: column;
            align-items: center;
            gap: $space-M;
        }

        .Text23 {
            display: flex;
            width: 136px;
            padding: 0;
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
        }
    }

    .Right {
        display: flex;
        padding: 0;
        flex-direction: column;
        align-items: flex-start;
        gap: $space-M;
        flex: 1 0 0;
    }
}