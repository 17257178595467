@import "../Master";

.DDHead {
    margin-top: -136px;
    background-color: $black-1;
    padding: calc($space-XL + 130px) 40px 0 40px;
    // For desktop
    @media only screen and (min-width: $desktop-resolution) {
        min-height: calc(1080px - 130px - $space-XL);
    }
}

.DDHead-Container {
    width: 100%;
    margin: 0 auto;

    @media (min-width: $desktop-resolution) {
        width: 640px;
    }

    .Logo {
        margin: $space-XL 0 $space-XXL;
    }

    .Head {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $space-XL;

        // For desktop
        @media only screen and (min-width: $desktop-resolution) {
            gap: $space-XXL;
        }

        padding: 0 0 $space-XL 0;
    
        .Title {
            @extend .White-2;
            @extend .Title-1;
    
            margin: 0;
        }
        
        .Image {

            width: 100%;

            img {
                width: 100%;
                // height: 256px;
                // object-fit: cover;
            }
        }

        .Video {
            video {
                width: 100%;
                // height: 256px;
                // object-fit: cover;
            }
        }

        .Skill-Section {
            display: flex;
            flex-direction: column;
            gap: $space-M;
        }
    
        .Skill-Info {
            @extend .White-3;
            @extend .Body-2;
        }
    
        .Skill {
            display: flex;
            padding: 0;
            align-items: flex-start;
            align-content: flex-start;
            gap: $space-S;
            align-self: stretch;
            flex-wrap: wrap;
    
            p {
                @extend .White-1;
                @extend .Body-3;
    
                display: flex;
                padding: $space-S $space-M;
                align-items: center;
                gap: 10px;
    
                border-radius: $space-L;
                border: 1px solid $black-3;
                background: $black-1;
            }
        }
    }
}