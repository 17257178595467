@import "../Master";

.List-1 {
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-M;
    align-self: stretch;

    .Item {
        display: flex;
        padding: 0 $space-S;
        justify-content: flex-end;
        align-items: flex-start;
        gap: $space-S;
        align-self: stretch;

        .Number-Section {
            display: flex;
            padding: 3px 0px 7px 0px;
            justify-content: center;
            align-items: center;
            gap: 0;

            .Number {
                display: flex;
                padding: 0 10px;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                gap: 10px;

                border-radius: 20px;
                background: $black-1;
                margin-top: 5px;

                p {
                    @extend .Body-3;
                    @extend .White-1;
                }
            }

        }
    }
}
