@import "../Master";

.Quote-2 {
    display: flex;
    padding: $space-M $space-M $space-L $space-M;
    align-items: flex-start;
    gap: $space-S;
    align-self: stretch;

    border-radius: $space-S;
    background: $white-3;

    flex-direction: column;

    @media (min-width: $desktop-resolution) {
        flex-direction: row;
    }

    .Content {
        display: flex;
        padding: 0;
        flex-direction: column;
        align-items: flex-start;
        gap: $space-M;
        flex: 1 0 0;

        .Heading {
            @extend .Black-1;
            @extend .Title-3;

            align-self: stretch;

            margin: 0;
        }
    }
}