.Black-1 {
    color: $black-1;
}

.Black-2 {
    color: $black-2;
}

.Black-3 {
    color: $black-3;
}

.White-1 {
    color: $white-1;
}

.White-1-Hover:hover {
    @extend .White-1;
}

.White-2 {
    color: $white-2;
}

.White-3 {
    color: $white-3;
}

.Green-1 {
    color: $green-1;
}

.Figma-Ref {
    color: $figma-reference;
}

.Bg-Black-1 {
    background: $black-1;
}

.Bg-Black-1-Hover:hover {
    @extend .Bg-Black-1;
}

.Bg-Black-2 {
    background: $black-2;
}

.Bg-Black-2-Hover:hover {
    @extend .Bg-Black-2;
}

.Bg-Black-3 {
    background: $black-3;
}

.Bg-Black-3-Hover:hover {
    @extend .Bg-Black-3;
}

.Bg-White-1 {
    background: $white-1;
}

.Bg-White-2 {
    background: $white-2;
}

.Bg-White-3 {
    background: $white-3;
}

.Bg-White-3-Hover:hover {
    @extend .Bg-White-3;
}

.Bg-Green-1 {
    background: $green-1;
}

.Bor-Black-1 {
    border: 1px solid $black-1;
}

.Bor-Black-2 {
    border: 1px solid $black-2;
}

.Bor-Black-3 {
    border: 1px solid $black-3;
}

.Bor-White-1 {
    border: 1px solid $white-1;
}

.Bor-White-2 {
    border: 1px solid $white-2;
}

.Bor-White-3 {
    border: 1px solid $white-3;
}

.Bor-Green-1 {
    border: 1px solid $green-1;
}

.Corner-Radius {
    border-radius: $corner-radius;
}