@import "../Master";

.Carousel {
    cursor: grab;
}

.Carousel-Swapper {
    display: flex;
    flex-direction: row;
    gap: $space-S;

    overflow: scroll;
    @media screen and (min-width: $desktop-resolution) {
        overflow: hidden;
    }
}

.Carousel-Image {
    img {
        border-radius: 4px;
        object-fit: cover;
    }
}