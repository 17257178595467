@import "Master";

.LD-Work {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    margin: $space-XL 0 0 0;
    
    .Title {
        @extend .Black-2;
        @extend .Title-3;
    }

    > .Item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $space-M;
        padding: $space-M;
        margin: 0 (-$space-M) 0 (-$space-M);
        border-radius: $space-M;
        text-decoration: none;
        background-color: transparent;
        transition: background-color 0.35s linear;
        cursor: pointer;
        .Image {

            width: 100%;

            img {
                width: 100%;
                // height: 256px;
                // object-fit: cover;
                border-radius: $space-S;
                background: $white-1;
            }
        }

        .Video {
            video {
                width: 100%;
                // height: 256px;
                // object-fit: cover;
                border-radius: $space-S;
            }
        }
        
        .Heading {
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            
            p {
                @extend .Black-1;
                @extend .Title-2;
                
                flex: 1 0 0;
            }
            
            img {
                width: $space-M;
                height: $space-M;
            }
        }
        
        .Body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: $space-L;
            align-self: stretch;

            // For desktop
            @media only screen and (min-width: $desktop-resolution) {
                flex-direction: row;
            }
            
            .Left {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: $space-S;
                align-self: stretch;
                
                width: 100%;
                
                .Description {
                    @extend .Black-1;
                    @extend .Body-2;
                }
                
                .List-Element {
                    display: flex;
                    align-items: flex-start;
                    gap: 8px;
                    
                    .Icon {
                        display: flex;
                        padding-top: 7px;
                        align-items: flex-start;
                        gap: 10px;

                        // For desktop
                        @media only screen and (min-width: $desktop-resolution) {
                            padding-top: 10px;
                        }
                        
                        img {
                            width: 12px;
                            height: 12px;
                        }
                    }
                    
                    .Text {
                        @extend .Black-1;
                        @extend .Body-2;
                    }
                }
            }
            
            .Right {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                gap: $space-M;
                align-self: stretch;
                flex-wrap: wrap;

                // For desktop
                @media only screen and (min-width: $desktop-resolution) {
                    flex-direction: column;
                    flex-shrink: 0;
                }
                
                .Role {
                    @extend .Body-3;

                    display: flex;
                    flex-direction: column;
                    gap: $font-space-body-3;

                    // For desktop
                    @media only screen and (min-width: $desktop-resolution) {
                        width: 100%;
                    }
                }

                .Skill {
                    @extend .Body-3;
                    display: flex;
                    flex-direction: column;
                    gap: $font-space-body-3;
                }

                .Read-Full-Story {
                    display: flex;
                    padding-top: 12px;
                    align-items: center;
                    gap: 10px;

                    p {
                        color: $green-1;

                        font-family: Manrope;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 180%; /* 32.4px */
                        letter-spacing: 0.36px;
                        text-decoration-line: underline;
                    }

                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
    .Item-Transform {
        @keyframes contentFade {
            0% { opacity: 1; }
            25% { opacity: 0.75; }
            50% { opacity: 0.5; }
            75% { opacity: 0.25; }
            100% { opacity: 0; }
        }
        
        .Image {
            animation: contentFade .5s linear;
        }
        
        .Heading {
            animation: contentFade .5s linear;
        }
        
        .Body {
            animation: contentFade .5s linear;
        }
    }
    .Item:hover {
        background-color: $white-1;
    }
}
.Divider {
    height: $space-M;
}
.Full-Story-Arrow {
    opacity: 0;
}
.Item:hover .Full-Story-Arrow {
    opacity: 100%;
}