@import "../Master";

.DDAuuu {
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-S;
    align-self: stretch;

    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.Show.DDAuuu {
    opacity: 1;
}

.Space-0.DDAuuu {
    gap: 0;
}

.Space-S.DDAuuu {
    gap: $space-S;
}

.Space-M.DDAuuu {
    gap: $space-M;
}

.Space-L.DDAuuu {
    gap: $space-L;
}

.Space-XL.DDAuuu {
    gap: $space-XL;
}

.Space-XXL.DDAuuu {
    gap: $space-XXL;
}

.Go-Column.DDAuuu {
    flex-direction: column;
}

.Go-Row.DDAuuu {
    flex-direction: row;
}

.Go-D-Column.DDAuuu {
    @media (min-width: $desktop-resolution) {
        flex-direction: column;
    }
}

.Go-D-Row.DDAuuu {
    @media (min-width: $desktop-resolution) {
        flex-direction: row;
    }
}

.Wrap {
    flex-wrap: wrap;
}

.Align-Self-End {
    align-self: flex-end;
}

.Align-Center {
    align-items: center;
}

.Solution-Section {
    display: flex;
    // padding: $space-M;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-S;

    // @extend .Corner-Radius;
    @extend .Bg-White-1;

    
    .Block-Heading {
        display: flex;
        padding: 0;
        align-items: center;
        gap: $space-S;
        align-self: stretch;

        img {
            width: 20px;
            height: 20px;
        }
    }

    > img {
        width: 100%;
    }
}

.Card {
    display: flex;
    padding: $space-L $space-M;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: $space-S;
    align-self: stretch;

    @extend .Corner-Radius;
    @extend .Bg-White-2;

    .Block-Heading {
        display: flex;
        padding: 0;
        align-items: flex-start;
        gap: $space-S;
        align-self: stretch;
    }

    // .Image {
    //     width: 240px;
    //     height: 160px;

    //     border-radius: 4px;
    //     background: lightgray 50% / cover no-repeat;
    // }

    .Photos {
        display: flex;
        padding: 0;
        align-items: flex-start;
        align-content: flex-start;
        gap: $space-M;
        align-self: stretch;
        flex-wrap: wrap;

        flex-grow: 1;
        
        .Item {
            display: flex;
            width: 210px;
            padding: 0;
            flex-direction: column;
            align-items: flex-start;
            gap: $space-S;

            // .Image {
            //     width: 100%;
            //     height: 140px;
            // }

            .Caption {
                @extend .Black-2;
                @extend .Body-3;
                @extend .Bold;
            }
        }
    }

    ul {
        padding: 0 $space-M;
    }
}

.Space-S.Card {
    gap: $space-S;
}

.Space-M.Card {
    gap: $space-M;
}

.Space-L.Card {
    gap: $space-L;
}

.Space-XL.Card {
    gap: $space-XL;
}

.Space-XXL.Card {
    gap: $space-XXL;
}

.Go-Grow-1 {
    flex-grow: 1;
}

.Go-Shrink-1 {
    flex-shrink: 1;
}

.Go-Basis-0 {
    flex-basis: 0;
}

.Align-End {
    align-items: flex-end;
}

.Justify-End {
    justify-content: flex-end;
}

.Justify-Center {
    justify-content: center;
}

.Line {
    width: 100%;
    height: 0.5px;
    padding: 0;
    background: #98A4A2;
    margin: 0;
}

.Custom-Chip-1 {
    border-radius: 16px;
    border: 1px solid $black-2;
    padding: 0 $space-S;
}