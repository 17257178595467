@import "../Master";

.DDIconee {
    display: flex;
    padding: 0;
    align-items: center;
    gap: $space-S;

    .Icon {
        width: 20px;
        height: 20px;
    }
}