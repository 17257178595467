@import "Master";

body {
    background: $white-2;
}

.Top-Background {
    width: 100%;
    height: 510px;

    background: $black-1;

    z-index: -1;

    position: absolute;
}

.Appx {
    display: flex;
    padding: $space-XL 0 $space-M 0;
    flex-direction: column;
    gap: $space-XXL;
}

.Appx2 {
    @extend .Appx;
}

.Sections {
    display: flex;
    align-items: flex-start;

    .Middle {
        margin: 0 auto;
        width: 640px;
    
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .MiddleBody {
        @extend .Middle;
        
        gap: $space-XXL;
        z-index: 1;
    }

    .MiddleBodyAfter {
        @extend .MiddleBody;

        align-items: center;
        width: 100%;
    }

    .EmptySection {
        flex-grow: 1;
        width: auto;
    }
}

.Centerizer {
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: center;
    gap: $space-XL;
    align-self: stretch;

    background-color: $white-1;

    

    .Cont4 {
        display: flex;
        padding: 0;
        flex-direction: column;
        align-items: flex-start;
        gap: $space-L;

        width: 640px;
        margin: 0 auto;

        

        .Frame627079 {
            display: flex;
            width: 100%;
            padding: $space-M;
            flex-direction: column;
            align-items: flex-start;
            gap: $space-S;

            border-radius: $corner-radius;
            background: $white-2;

            img {
                width: 100%;
            }
        }

        .Text {
            display: flex;
            padding: 0;
            flex-direction: column;
            align-items: flex-start;
            gap: $space-M;

            .Heading {
                @extend .Black-1;
                @extend .Title-2;

                margin: 0;
            }

            p {
                @extend .Black-1;
                @extend .Body-2;
            }
        }
    }
}