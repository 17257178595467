// Global variable
$space-S: 12px;
$space-M: 24px;
$space-L: 40px;
$space-XL: 64px;
$space-XXL: 96px;

// Font space
$font-space-title-1: 0px;
$font-space-title-2: 0px;
$font-space-title-3: 0px;
$font-space-body-1: 12px;
$font-space-body-2: 18px;
$font-space-body-3: 8px;

// Colors
$black-1: #000000;
$black-2: #788787;
$black-3: #98A4A2;
$black-4: #C8D0CE;
$white-1: #FFFFFF;
$white-2: #F1F3F3;
$white-3: #E3E8E8;
$green-1: #3E8478;

// Resolution
$desktop-resolution: 740px;

// Corner radius of card
$corner-radius: 8px;

// Diagonal card color
$diagonal-card: #5CB2A4;

// Figma reference
$figma-reference: #691ECA;