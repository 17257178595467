@import "Master";

.Adm-Sidebar {
    @extend .Padding-L;
    @extend .Padding-Bottom-M;
    @extend .Flex;
    @extend .F-Col;
    @extend .Gap-XL;
    @extend .Bg-Black-1;
    
    min-width: 200px;
    min-height: 100vh;
}

.Essay-Table {
    border-collapse: collapse;
    
    th, td {
        border: 1px solid $black-1;
        @extend .Padding-S;
    }
}

.File-Table {
    @extend .Essay-Table;
}
