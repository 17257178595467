.Title-1 {
    /* Title 1 */
    font-family: Manrope;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.4px;

    // For desktop
    @media only screen and (min-width: $desktop-resolution) {
        font-size: 40px;
        line-height: 150%;
    }
}

.Title-2 {
    /* Title 2 */
    font-family: Manrope;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;

    // For desktop
    @media only screen and (min-width: $desktop-resolution) {
        font-size: 26px;
        line-height: 150%;
    }
}

.Title-3 {
    /* Title 3 */
    font-family: Manrope;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.54px;
    text-transform: uppercase;

    // For desktop
    @media only screen and (min-width: $desktop-resolution) {
        font-size: 16px;
        line-height: 150%;
    }
}

.Body-1 {
    /* Body 1 */
    font-family: Manrope;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%; /* 35.2px */
    letter-spacing: 0.22px;

    ol, ul {
        margin: $font-space-body-1 0 0 0;
    }

    br {
        display: block;
        content: ' ';
        margin: $font-space-body-1 0 0 0;
    }

    // For desktop
    @media only screen and (min-width: $desktop-resolution) {
        font-size: 20px;
    }
}

.Body-2 {
    /* Body 2 */
    font-family: Manrope;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: 0.36px;

    br {
        display: block;
        content: ' ';
        margin: $font-space-body-2 0 0 0;
    }

    ol, ul {
        margin: $font-space-body-2 0 0 0;
        padding-left: 20px;
    }

    // Add margin-bottom $font-space-body-2 if there is more content after ol and ul
    > ol, > ul, > p {
        margin-bottom: $font-space-body-2;
    }

    // For desktop
    @media only screen and (min-width: $desktop-resolution) {
        font-size: 16px;
        line-height: 26px;
    }

    .FW-400 {
        font-weight: 400;
    }

    .FW-600 {
        font-weight: 600;
    }
}

.Body-2.FW-400 {
    font-weight: 400;
}

.Body-2.FW-600 {
    font-weight: 600;
}

.Body-3 {
    /* Body 3 */
    font-family: Manrope;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0.3px;

    .FW-400 {
        font-weight: 400;
    }

    .FW-600 {
        font-weight: 600;
    }

    br {
        display: block;
        content: ' ';
        margin: $font-space-body-3 0 0 0;
    }

    ol, ul {
        margin: $font-space-body-3 0 0 0;
    }

    // For desktop
    @media only screen and (min-width: $desktop-resolution) {
        font-size: 14px;
        line-height: 18px;
    }
}

.Body-3.Bold {
    font-weight: 600;
}

.Body-3.FW-400 {
    font-weight: 400;
}

.Body-3.FW-600 {
    font-weight: 600;
}

.Bold {
    font-weight: 600;
}

.Center {
    text-align: center;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

ul, ol {
    margin: 0;
}

.Padding-S {
    padding: $space-S;
    ul {
        padding: 0 0 0 $space-S;
    }
    ol {
        padding: 0 0 0 $space-S;
    }
}

.Padding-M {
    padding: $space-M;
    ul {
        padding: 0 0 0 $space-M;
    }
    ol {
        padding: 0 0 0 $space-M;
    }
}

.Padding-L {
    padding: $space-L;
    ul {
        padding: 0 0 0 $space-L;
    }
    ol {
        padding: 0 0 0 $space-L;
    }
}

.Padding-XL {
    padding: $space-XL;
    ul {
        padding: 0 0 0 $space-XL;
    }
    ol {
        padding: 0 0 0 $space-XL;
    }
}

.Padding-XXL {
    padding: $space-XXL;
    ul {
        padding: 0 0 0 $space-XXL;
    }
    ol {
        padding: 0 0 0 $space-XXL;
    }
}

.Padding-Top-S {
    padding-top: $space-S;
}

.Padding-Top-M {
    padding-top: $space-M;
}

.Padding-Top-L {
    padding-top: $space-L;
}

.Padding-Top-XL {
    padding-top: $space-XL;
}

.Padding-Top-XXL {
    padding-top: $space-XXL;
}

.Padding-Bottom-S {
    padding-bottom: $space-S;
}

.Padding-Bottom-M {
    padding-bottom: $space-M;
}

.Padding-Bottom-L {
    padding-bottom: $space-L;
}

.Padding-Bottom-XL {
    padding-bottom: $space-XL;
}

.Padding-Bottom-XXL {
    padding-bottom: $space-XXL;
}

.Padding-Left-S {
    padding-left: $space-S;
    ul, li { 
        padding-left: $space-S;
    }
}

.Padding-Left-M {
    padding-left: $space-M;
    ul, li {
        padding-left: $space-M;
    }
}

.Padding-Left-L {
    padding-left: $space-L;
    ul, li {
        padding-left: $space-L;
    }
}

.Padding-Left-XL {
    padding-left: $space-XL;
    ul, li {
        padding-left: $space-XL;
    }
}

.Padding-Left-XXL {
    padding-left: $space-XXL;
    ul, li {
        padding-left: $space-XXL;
    }
}

.Padding-Right-S {
    padding-right: $space-S;
}

.Padding-Right-M {
    padding-right: $space-M;
}

.Padding-Right-L {
    padding-right: $space-L;
}

.Padding-Right-XL {
    padding-right: $space-XL;
}

.Padding-Right-XXL {
    padding-right: $space-XXL;
}