@import "Master";

body {
  background: $white-2;
}

.LD-App {
  display: flex;
  padding: $space-XXL $space-L $space-XL $space-L;
  flex-direction: column;
  align-items: flex-start;
  gap: $space-XXL;

  min-width: 280px;

  // By default, set opacity to 0
  // It will be animated later
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  // For desktop
  @media only screen and (min-width: $desktop-resolution) {
    // Center
    margin: 0 auto;

    display: flex;
    padding: $space-XXL 0 $space-XL 0;
    flex-direction: column;
    gap: $space-XXL;

    max-width: 640px;
  }
}

.App-logo {
  width: 100px;
  height: 10px;
}