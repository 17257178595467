@import 'Master';

.Essay-View {
    // Set opacity to 0
    opacity: 0;
    // Animation on opacity
    transition: opacity 0.5s ease-in-out;

    width: 100%;

    .Content-Essay {
        @extend .Flex;
        @extend .F-Col;
        
        > p {
            margin-top: $space-L;
            @extend .Body-2;
            @extend .Black-1;
        }

        > h1 + p, > h2 + p, > h3 + p, > h4 + p, > h5 + p, > h6 + p {
            margin-top: $space-S;
        }

        > h1 {
            margin-top: $space-L;
            @extend .Title-1;
            @extend .Black-1;
        }

        > h2 {
            margin-top: $space-L;
            @extend .Title-2;
            @extend .Black-1;
        }

        > h3 {
            margin-top: $space-L;
            @extend .Title-3;
            @extend .Black-1;
        }
        
        a {
            @extend .Body-2;
            @extend .FW-600;
            @extend .Black-1;
        }

        a::after {
            display: inline-block;
            content: '✦';
            text-decoration: none;
            vertical-align: super;
            font-size: smaller;
        }
        a:hover::after {
            display: inline-block;
            content: '◥';
            text-decoration: none;
            vertical-align: super;
            font-size: small;
        }

        a:hover {
            @extend .Black-2;
        }

        > ul {
            margin-top: $space-L;
            @extend .Padding-Left-M;
            > li {
                @extend .Body-2;
                @extend .Black-1;
            }
        }

        > ol {
            margin-top: $space-L;
            @extend .Padding-Left-M;

            > li {
                @extend .Body-2;
                @extend .Black-1;
            }
        }
    }

    .Footer-Note {
        margin-top: $space-L;
        padding-top: $space-S;
        border-top: 1px solid $black-3;

        ol {
            list-style-type: none;
            counter-reset: custom-counter;
        
            padding-left: 0;
        }

        ol > li {
            @extend .Body-2;
            @extend .Black-2;
        }

        ol > li:before {
            content: "[" counter(custom-counter) "] ";
            counter-increment: custom-counter;
        }

        a {
            @extend .Body-2;
            @extend .FW-600;
            @extend .Black-1;
        }

        a::after {
            display: inline-block;
            content: '✦';
            text-decoration: none;
            vertical-align: super;
            font-size: smaller;
        }

        a:hover {
            @extend .Black-2;
        }

        p {
            @extend .Body-2;
            @extend .Black-2;
        }
    }
}